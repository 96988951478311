import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Product, Variation } from "../../types/produtos";

export const CardMUI = ({ variant }: { variant: Product }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [height, setHeight] = useState(
    variant.body.pictures[0].size.split("x")[1]
  );
  const [width, setWidth] = useState(
    variant.body.pictures[0].size.split("x")[0]
  );

  const images = variant.body.pictures;

  useEffect(() => {
    setHeight(variant.body.pictures[0].size.split("x")[1]);
    setWidth(variant.body.pictures[0].size.split("x")[0]);
  }, [variant]);

  return (
    <Card
      sx={{
        maxWidth: 400,
        width: "100%",
        height: 400,
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CardMedia
        sx={{
          maxHight: height + "px",
          height: Number(height) >= 280 ? 210 : 170,
          maxWidth: width + "px",
          width: "100%",
        }}
        image={variant.body.pictures[0].url}
        title={variant.body.title}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsOpen(true);
          setImgIndex(images.length - 1);
        }}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {variant.body.title}
        </Typography>
      </CardContent>
      <Box display="flex" width="100%">
        <CardActions sx={{ justifyContent: "center", width: "100%" }}>
          <Button
            sx={{
              width: "100%",
              backgroundColor: "black",
              fontWeight: "600",
              ":hover": {
                backgroundColor: "black",
                opacity: "0.9",
                color: "white",
              },
            }}
            variant="contained"
            size="small"
            href={variant.body.permalink}
            target="_blank"
          >
            <Grid justifyContent="center" container gap={2} alignItems="center">
              <ShoppingCartIcon />
              Comprar
            </Grid>
          </Button>
        </CardActions>
      </Box>
      {isOpen && (
        <Lightbox
          mainSrc={images![imgIndex].url}
          nextSrc={images[(imgIndex + 1) % images.length].url}
          prevSrc={images[(imgIndex + images.length - 1) % images.length].url}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setImgIndex((imgIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setImgIndex((imgIndex + 1) % images.length)}
        />
      )}
    </Card>
  );
};
